import StatusLabel from '@/common/components/StatusLabel';
import { ApprovalStateIdPrefix, type WorkflowStateId } from '@/common/graphql/scalars';
import { assertNever } from '@/utils/types/types';
import { useTranslation } from 'react-i18next';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { GoIssueDraft } from 'react-icons/go';
import { MdCancel, MdCheckCircle, MdStopCircle } from 'react-icons/md';

type Props = {
  stateId: WorkflowStateId;
};

export default function WorkflowStatusBadge({ stateId }: Props) {
  const { t } = useTranslation('workflows');
  switch (stateId) {
    case 'Created':
      return (
        <StatusLabel
          status={{
            label: t('status.created'),
            color: 'status.5',
            icon: GoIssueDraft,
          }}
        />
      );
    case 'Rejected':
      return (
        <StatusLabel
          status={{
            label: t('status.rejected'),
            color: 'status.3',
            icon: MdCancel,
          }}
        />
      );
    case 'Cancelled':
      return (
        <StatusLabel
          status={{
            label: t('status.cancelled'),
            color: 'status.5',
            icon: MdStopCircle,
          }}
        />
      );
    case 'Approved':
      return (
        <StatusLabel
          status={{
            label: t('status.approved'),
            color: 'status.4',
            icon: MdCheckCircle,
          }}
        />
      );
    default: {
      if (stateId.startsWith(ApprovalStateIdPrefix)) {
        return (
          <StatusLabel
            status={{
              label: t('status.approval'),
              color: 'status.1',
              icon: FaArrowAltCircleRight,
            }}
          />
        );
      } else {
        assertNever(stateId);
      }
    }
  }
}
